import { Close } from '@material-ui/icons';
import { Alert, AlertTitle, IconButton } from '@mui/material';
import React from 'react'
import { useTranslation } from 'react-i18next';

const Result = (props) => {

    const { result, onClose } = props;
    if (!result) return null;

    if (result.isSuccess()) return <Success result={result} onClose={onClose} />
    return <Error result={result} onClose={onClose} />
}

const ErrorAlert = (props) => {
  
  const { t } = useTranslation();
  const message = props.message;
  const onClose = props.onClose;

  return (
    <Alert action={
      <IconButton
        aria-label="close"
        color="inherit"
        size="small"
        onClick={onClose}>
        <Close fontSize="inherit" />
      </IconButton>
    }
    severity="error"
    sx={{ mb: 2 }}>
      <AlertTitle>{t("general.error")}</AlertTitle>
      {message}
  </Alert>
  );
}

const Error = (props) => {

  const { t } = useTranslation();

  const { result, onClose } = props;
  const messageKey = result.messageKey;
  const displayMessage = messageKey ? t(messageKey) : result.localizedMessage ?? result.message ?? t("general.unknown_error");

  return <ErrorAlert message={displayMessage} onClose={onClose} />
}

const Success = (props) => {

  const { t } = useTranslation();
  const { onClose, result } = props;
  const { messageKey } = result;

  return (
      <Alert action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={onClose}>
            <Close fontSize="inherit" />
          </IconButton>
        }
        severity="success"
        sx={{ mb: 2 }}>
          <AlertTitle>{t("general.success")}</AlertTitle>
          {t(messageKey)}
      </Alert>
  )
}

export default Result;